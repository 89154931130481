/* Provide sufficient contrast against white background */
html,
body {
    min-height: 100%;
    padding: 0;
    margin: 0;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

td {
    padding: 2px;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.autoComplete {
    font-family: monospace, monospace;
}

.helloWorldLayout {
    width: 80%;
    margin: auto;
    max-width: 1500px;
}

.selectionRow {
    width: 80%;
    margin: auto;
    max-width: 1500px;
    padding: 15px;
}

#box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

#remaining {
    flex-grow: 1;
}

#root {
    height: 100vh;
}

#transition-popper {
    z-index: 100;
}